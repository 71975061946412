// Global overrides for Angular Material

.mat-mdc-form-field {
  &:not(.mat-mdc-paginator-page-size-select) {
    width: 100%;
    .mat-mdc-text-field-wrapper {
      border-radius: 4px;
      .mdc-line-ripple {
        display: none;
      }

      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-icon-prefix > .align-self-end {
          position: relative;
          top: 5px;
          left: 4px;
          padding: 0;
        }
        .mat-mdc-form-field-icon-suffix {
          margin-right: 0.5rem;
          color: $primary-color;
        }
        .mat-mdc-form-field-infix {
          font-size: 14px;
          min-height: 56px;

          .mdc-floating-label {
            text-transform: uppercase;
            color: $primary-color;
            font-weight: 600;
            font-size: 14px;
            letter-spacing: 0.33px;
          }
        }
      }
    }
  }

  .mat-mdc-form-field-subscript-wrapper {
    height: 0.8rem;
    .mat-mdc-form-field-hint-wrapper,
    .mat-mdc-form-field-error-wrapper {
      top: -6px;
      font-size: 10px;
    }
  }

  &.mat-form-field-invalid > .mat-mdc-text-field-wrapper {
    .mat-mdc-form-field-infix {
      .mdc-floating-label {
        color: $danger-color;
      }
    }
  }
}

.mat-mdc-menu-panel {
  .mat-mdc-menu-content {
    padding: 0;
  }
}

div.mat-mdc-select-panel {
  padding: 0 !important;
}

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs
  > .mat-mdc-tab-header
  .mat-mdc-tab {
  flex-grow: 0 !important;
}

.mdc-tab__content {
  min-width: 140px;
  max-width: 140px;
}

.mdc-tab__text-label {
  display: block !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis !important;
}
.mdc-button.mdc-button--raised.no-padding {
  padding: 0;
}

// layouts/content-layout
.mat-drawer-inner-container {
  overflow: hidden !important;
}

// CDK drag and drop lists
.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
.cdk-drag-animating {
  transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
}

// make chip lists transparent (like Product Highlights)
.chip-list-transparent {
  .mat-chip {
    background: transparent;
    margin: 0 4px !important;

    &:active {
      width: auto !important;
      padding: 0 0.5rem !important;
    }

    &:after {
      background: transparent;
    }

    .mat-chip-remove {
      color: #f6574d;
      margin: 0 0.4rem 0 0;
      opacity: 0.6;

      &:hover {
        opacity: 0.8 !important;
      }
    }
  }
}

// helper class to make chips a bit smaller
.mat-chip.chip-sm {
  min-height: 28px;
}

.product-group-input-categories,
.product-group-input-related {
  .mat-chip:active {
    box-shadow: none !important;
    padding: 0 !important;
  }
}

.faux-mat-label-wrapper {
  .faux-mat-label {
    color: rgba(0, 0, 0, 0.54);
    -webkit-transform: translateY(-1.28125em) scale(0.75) perspective(100px)
      translateZ(0.001px);
    transform: translateY(-1.28125em) scale(0.75) perspective(100px)
      translateZ(0.001px);
    -ms-transform: translateY(-1.28125em) scale(0.75);
    display: block;
    position: absolute;
    top: 0;
    left: -2px;
  }

  &:focus-within {
    .faux-mat-label {
      color: #1e77b2;
    }
  }
}

.mat-expansion-panel-header-title,
.mat-expansion-panel-header-description {
  flex-basis: 0;
}

// Option to overwrite material tab opacity
.mat-tab-opacity-1 {
  .mat-tab-label {
    opacity: 1;
  }
}

.mat-tab-body-wrapper-h-100 {
  .mat-mdc-tab-body-wrapper {
    height: 100%;
  }
}

.mat-tab-body-content-bg-light {
  .mat-mdc-tab-body-content {
    background: #efefef;
  }
}

.mobile-stepper {
  background-color: inherit !important;
  .mat-horizontal-stepper-wrapper {
    width: 100%;
  }
  .mat-horizontal-stepper-header-container {
    display: none !important;
  }

  .mat-horizontal-content-container {
    padding: 0;
  }
}
