@use '@angular/material' as mat;
@import './partials/palettes';
@include mat.core();

//** Color Palettes
$primary: mat.define-palette($primary-palatte);
$accent: mat.define-palette($accent-palatte);
$warning: mat.define-palette($warning-palatte);
$danger: mat.define-palette($danger-palatte);
$secondary: mat.define-palette($secondary-palatte);
$success: mat.define-palette($success-palatte);

//**  GLOBAL
$primary-color: mat.get-color-from-palette($primary, 500);
$accent-color: mat.get-color-from-palette($accent, 500);
$warning-color: mat.get-color-from-palette($warning, 500);
$secondary-color: mat.get-color-from-palette($secondary, 500);
$success-color: mat.get-color-from-palette($success, 500);
$danger-color: mat.get-color-from-palette($danger, 500);

// Setup Theme colors so we can iterate over them
$theme-colors: (
  'primary': $primary-color,
  'info': #1779ba,
  'secondary': $secondary-color,
  'success': $success-color,
  'warning': $warning-color,
  'accent': $accent-color,
  'danger': $danger-color,
);

$wilco-blue: #003b5c;
$sky-fall: #9ac4e7;
$flyway: #62a0cc;
$cyberspace: #444f59;
$paperplate: #efeff0;
$wilco-tan: #ddcba4;
$wilco-dark: #1f1c1d;
$wilco-dark-light: #404040;
$wilco-gray: #e5e5e5;
$wilco-gray-darker: #d7d7d7;
$wilco-blue-darker: #072f4a;
$wilco-info-danger: #912c31;
$wilco-info-warning: #ffc107;
$wilco-gray-dark: #d7d7d7;
// $wilco-gray-dark: #e5e5e5;
$wilco-gray-light: #efefef;

//** TYPOGRAPHY
$body-font-family: 'Montserrat', Helvetica, Arial, sans-serif;
$secondary-font-family: $body-font-family;
$heading-font-family: $secondary-font-family;
$h2-font-size: 2rem;

//** SIDENAV
$sidenav-min-width: 50px;
$sidenav-max-width: 250px;
$sidenav-background-color: $cyberspace;
$sidenav-header-background-color: $wilco-blue;
$sidenav-header-height: 45px;
$sidenav-header-logo-height: 25px;
$sidenav-transition-opacity-time: 0.3s;
$sidenav-nav-active-background-color: darken($cyberspace, 5%);
$sidenav-nav-active-link-color: $wilco-tan;
$sidenav-nav-item-height: 50px;
$sidenav-nav-item-link-color: $paperplate;
$sidenav-nav-item-image-height: 30px;
$sidenav-user-background-color: darken($cyberspace, 5%);
$sidenav-secondary-nav-item-height: 40px;

//** APPLICATION SIDEBAR
$sidebar-background-color: #f5f6f7;
$sidebar-border-color: darken($sidebar-background-color, 5%);
$sidebar-header-background-color: $sidenav-background-color;
$sidebar-header-height: $sidenav-header-height + 1px;
$sidebar-header-font-size: 1.2rem;

// Function that picks a contrast color depending on the brightness of a color
@function contrast-color($color, $dark, $light) {
  @return if(lightness($color) < 55%, $light, $dark)
}