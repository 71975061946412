@import '../node_modules/@fontsource/montserrat/400.css';
@import '../node_modules/@fontsource/montserrat/400-italic.css';
@import '../node_modules/@fontsource/montserrat/500.css';
@import '../node_modules/@fontsource/montserrat/600.css';

@import '_settings';
@import 'partials/_theme';
@import 'partials/_flex';
@import 'partials/_helpers';
@import 'partials/_global';
@import 'partials/_mobile';
@import 'partials/_editor';
@import 'partials/_material';

@font-face {
  font-family: Arial;
  src: url(assets/fonts/arial/Arial.ttf) format('opentype');
}

@font-face {
  font-family: 'helvetica-neue-lt-std-97-black-condensed';
  src: url(assets/fonts/helvetica/Helvetica-Neue-LT-Com-97-Black-Condensed.ttf)
    format('opentype');
}

html,
body {
  height: 100%;
  margin: 0;
  overflow: hidden;
  box-sizing: border-box;
}

button {
  text-transform: capitalize !important;
}

// GLOBAL STYLES
.view-container {
  font-family: $body-font-family !important;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $heading-font-family !important;
  }
}

*:focus {
  outline: none;
}

.image div.draggable * {
  pointer-events: none;
}

.uppercase {
  text-transform: uppercase;
}

// Differentiate Dev/Testing environments from Production
.view-container {
  background: #003b5c;
  background: -moz-linear-gradient(
    180deg,
    rgba(1, 74, 115, 1) 0%,
    rgba(0, 70, 110, 1) 35%,
    rgba(0, 59, 92, 1) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(1, 74, 115, 1) 0%,
    rgba(0, 70, 110, 1) 35%,
    rgba(0, 59, 92, 1) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(1, 74, 115, 1) 0%,
    rgba(0, 70, 110, 1) 35%,
    rgba(0, 59, 92, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#014a73",endColorstr="#003b5c",GradientType=1);
}

.corner-ribbon-container {
  display: none;
}

.development,
.testing {
  .corner-ribbon-container {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    overflow: hidden !important;
    .corner-ribbon {
      width: 140px !important;
      background: #09843a !important;
      opacity: 0.8 !important;
      position: absolute !important;
      top: 10px !important;
      right: -50px !important;
      left: auto !important;
      text-align: center !important;
      line-height: 30px !important;
      color: #f0f0f0 !important;
      transform: rotate(45deg) !important;
      -webkit-transform: rotate(45deg) !important;
      z-index: 1000 !important;
    }
  }

  .app-view {
    background: -webkit-linear-gradient(
      top,
      #1e8eb2 0%,
      #1b85a5 39%,
      #177a8c 100%
    );
    background: linear-gradient(
      to bottom,
      #1e8eb2 0%,
      #1b85a5 39%,
      #177a8c 100%
    );
  }
}

// Fix Foundation overwriting Bootstrap badges
.badge {
  font-size: 80% !important;
  border-radius: 0.25rem !important;
  font-weight: 600;

  &--circle {
    border-radius: 50% !important;
    padding: 0.2rem 0.1rem !important;
  }
}

.badge-pill {
  padding: 0.3rem 0.6rem;
  border-radius: 10rem !important;
}

.show-skus-badge {
  border-radius: 0.25rem !important;
  padding: 0.3em 0.4em !important;
  background-color: #6c757d !important;
  font-weight: 600 !important;

  &:hover {
    background-color: $wilco-blue !important;
  }
}

.badge-circle {
  padding: 0.3rem 0 !important;
  border-radius: 50% !important;
}

// Fix Foudnation setting a disabled bg color
input:disabled,
input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: transparent !important;
}

// Fix Foundation overwriting Bootstrap buttons
.btn {
  border-radius: 0.2rem !important;
}

// Fix Foundation setting outlines on inputs
app-content-layout,
.cdk-overlay-container {
  [type='color'],
  [type='date'],
  [type='datetime-local'],
  [type='datetime'],
  [type='email'],
  [type='month'],
  [type='number'],
  [type='password'],
  [type='search'],
  [type='tel'],
  [type='text'],
  [type='time'],
  [type='url'],
  [type='week'],
  input {
    margin: 0;
    border: none;
    box-shadow: none;
    box-sizing: initial;
    background: transparent;
    &:focus {
      border: none;
      box-shadow: none;
      background: transparent;
    }
  }
  textarea {
    border: none !important;
    box-shadow: none !important;
    box-sizing: initial;
    background: transparent !important;
    height: auto;
    line-height: 18px !important;
    min-height: auto !important;
    display: inline-block !important;

    &:focus {
      border: none !important;
      box-shadow: none !important;
      background: transparent !important;
    }
  }
}

.card {
  margin-bottom: 0.5rem !important;
  cursor: pointer;
}

// mat tooltip background fix
.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

// undo the above for some things
.mat-select-search-inside-mat-option {
  .mat-select-search-input {
    height: 3em !important;
    line-height: 3em !important;
  }
}

// fix auth0 form height
.auth0-lock.auth0-lock .auth0-lock-input-wrap .auth0-lock-input {
  height: 40px !important;
}

// NG1 fixes
.app-view.view-frame {
  .ng-toad__list {
    list-style: none;

    .alert {
      background: none;
      border: none;
    }
  }
}

.custom-dialog-container .mat-dialog-container {
  padding: 0;
}

.mobile-dialog-container .mat-dialog-container {
  padding: 0;
  border-radius: 10px;
  display: flex;
}

.btn-primary {
  background-color: $wilco-tan !important;
  color: $wilco-dark !important;
}

.btn-tertiary {
  font-weight: 700 !important;
  border: none !important;
  text-transform: uppercase;

  &:hover {
    background-color: darken($wilco-tan, 10%) !important;
  }

  &.btn-lg {
    padding: 0.8rem 1.5rem !important;
  }

  &.lite {
    font-weight: 400 !important;
    font-size: 11px !important;
    letter-spacing: 0.33px;
  }
}

.btn-outline-secondary {
  color: #003b5c;

  &.btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.7rem;
  }
  &.lite {
    font-weight: 400 !important;
    font-size: 11px !important;
    letter-spacing: 0.33px;
  }
}

.btn-lg {
  padding: 10px 20px !important;
}

mat-label.custom-label {
  letter-spacing: 0.33px;
  font-weight: 600;
  font-size: 12px;
  color: $wilco-dark-light;

  span {
    color: #1f1c1d;
    font-weight: 400;
    text-transform: none;
  }
}

// Overlay panel for mat-select - Fulfillment -> view orders
.view-orders-status-panel {
  transform: translate3d(30px, 28px, 0) !important;
  min-width: 100% !important;
}

.orders-status-panel {
  transform: translate3d(0, 28px, 0) !important;
  min-width: 100% !important;
}

.user-name-panel {
  transform: translate3d(30px, 28px, 0) !important;
  min-width: 100% !important;
  overflow-x: hidden !important;
}

.focus-panel {
  transform: translate3d(6px, 40px, 0) !important;
  min-width: 100% !important;
}

.mock-cell {
  flex: 1;
  display: flex;
  align-items: center;
  overflow: hidden;
  word-wrap: break-word;
  min-height: inherit;
  height: 48px;

  &--name {
    flex: 2;
    padding-left: 1rem;
  }

  &.options {
    max-width: 55px;
    margin-left: 8px;
    padding-left: 24px;
  }
}

.mock-cell-spacer {
  flex: 0 0 4rem;
}

.mock-cell-content {
  overflow-y: auto;
  max-height: 500px;
}

@-webkit-keyframes flash-danger {
  from {
    color: $wilco-info-danger;
  }
  to {
    color: white;
    background-color: $wilco-info-danger;
  }
}
@-moz-keyframes flash-danger {
  from {
    color: $wilco-info-danger;
  }
  to {
    color: white;
    background-color: $wilco-info-danger;
  }
}
@-ms-keyframes flash-danger {
  from {
    color: $wilco-info-danger;
  }
  to {
    color: white;
    background-color: $wilco-info-danger;
  }
}
@-o-keyframes flash-danger {
  from {
    color: $wilco-info-danger;
  }
  to {
    color: white;
    background-color: $wilco-info-danger;
  }
}
@keyframes flash-danger {
  from {
    color: $wilco-info-danger;
  }
  to {
    color: white;
    background-color: $wilco-info-danger;
  }
}

.flash-danger {
  color: $wilco-info-danger;
  -webkit-animation: flash-danger 0.75s infinite alternate;
  -moz-animation: flash-danger 0.75s infinite alternate;
  -ms-animation: flash-danger 0.75s infinite alternate;
  -o-animation: flash-danger 0.75s infinite alternate;
  animation: flash-danger 0.75s infinite alternate;
}

.route-active {
  background-color: #cbe7f7;
}
