// Global overrides for angular-editor

.angular-editor-toolbar-set {
  display: none !important;

  &:first-child,
  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4),
  &:nth-child(5),
  &:nth-child(6),
  &:nth-child(11),
  &:nth-child(12),
  &:nth-child(13) {
    display: inline-block !important;
  }
}

.editor-borderless {
  #editor {
    border: none;
  }
}

.angular-editor {
  .angular-editor-textarea {
    p {
      margin-top: 0.5rem;
    }
  }
}
