@use '@angular/material' as mat;
@import '_settings';
@include mat.core();

$typography: mat.define-typography-config(
  $font-family: 'Montserrat',
  $body-1:
    mat.define-typography-level(
      $font-size: 14px,
      $letter-spacing: 0.5px,
    ),
  $button:
    mat.define-typography-level(
      $font-size: 14px,
      $line-height: 0px,
      $letter-spacing: 0.5px,
      $font-weight: 800,
    ),
);

/**
* Whatever palettes are added to this map with enable a class to be added
* to a material component named mat-${name}. Add a the same naming convention
* to the $theme-colors map in _settings.scss and the map below will create the
* appropriate classes
*/
$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
      warning: $warning,
      danger: $danger,
      secondary: $secondary,
    ),
    typography: $typography,
    density: 0,
  )
);

@include mat.all-component-themes($my-theme);

.mat-mdc-button {
  border-radius: 4px;
}

.mat-mdc-outlined-button:not(:disabled) {
  border-color: var(--mdc-outlined-button-label-text-color) !important ;
}

/**
* Setup additional colors for buttons
*/
@each $key, $color in $theme-colors {
  .mdc-button.mdc-button--raised.mat-#{$key}:not(:disabled) {
    background-color: $color;
    color: contrast-color($color, #000000, #ffffff);
  }

  // Who invented this dumb naming convention omg!!!
  .mdc-button.mdc-button--outlined.mat-#{$key}:not(:disabled) {
    color: $color;
  }

  .mdc-button.mdc-button--outlined.mat-#{$key}:hover {
    background-color: rgba($color, 0.01);
  }

  .mat-button.mat-#{$key}:hover,
  .mat-raised-button.mat-#{$key}:hover {
    background-color: rgba($color, 0.01);
  }

  .mat-raised-button.mat-#{$key},
  .mat-flat-button.mat-#{$key},
  .mat-fab.mat-#{$key},
  .mat-mini-fab.mat-#{$key} {
    color: contrast-color($color, #000000, #ffffff);
    background-color: $color;
  }
}
