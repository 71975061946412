.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.justify-content-start {
  justify-content: start;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: end;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-self-end {
  justify-self: end;
}

.align-items-center {
  align-items: center;
}

.align-self-start {
  align-self: start;
}

.align-self-center {
  align-self: center;
}

.align-self-end {
  align-self: end;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}
.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.cursor-pointer {
  cursor: pointer;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.position-absolute {
  position: absolute;
}

.position-relative {
  position: relative;
}

.d-inline-block {
  display: inline-block;
}

.d-none {
  display: none;
}

@media (min-width: 415px) {
  .flex-xs-row {
    flex-direction: row;
  }
}

@media (min-width: 1250px) {
  .flex-lg-row {
    flex-direction: row;
  }
  .mr-lg-3 {
    margin-right: 1rem;
  }
  .ml-lg-3 {
    margin-left: 1rem;
  }
  .mx-lg-3 {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .mt-lg-4 {
    margin-top: 1.5rem;
  }
}

@media (min-width: 1400px) {
  .flex-xl-row {
    flex-direction: row;
  }
}
