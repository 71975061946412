@import '_settings.scss';
@import '_helpers.scss';

@mixin mobile-scroll {
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.mobile-scroll {
  @include mobile-scroll;
}

.mobile-modal {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: $wilco-gray;
  top: 30px;
  height: calc(100% - 1rem);
  padding: 1rem;
  border-radius: 30px 30px 0 0 !important;
  flex: 1;
  @include mobile-scroll;

  div {
    max-width: 750px;
  }
}

.d-md-none {
  @media screen and (min-width: 767px) {
    display: none;
  }
}

.d-sm-none {
  @media screen and (max-width: 767px) {
    display: none;
  }
}
