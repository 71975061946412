body {
  overflow-x: hidden;
}

// flip a font awesome icon with the .flip class
fa-icon {
  svg {
    transition: all 0.2s;
  }

  &.flip svg {
    transform: rotate(180deg);
  }
}

.file-drop-zone {
  border: 1px dashed #ccc;

  &.ngx-file-drop__drop-zone--over {
    background: rgba(0, 0, 0, 0.025) !important;
  }

  .file-drop-zone-content {
    position: relative;

    button {
      width: 100%;
      font-size: 0.8rem;
      padding: 5rem;
      background: #fafafa;

      fa-icon {
        display: inline-block;
        color: #ccc;
        font-size: 2rem;
        padding-bottom: 0.5rem;
      }
    }
  }
}

.wilco-tree-invisible {
  display: none;
}

.wilco-tree ul,
.wilco-tree li {
  margin-top: 0;
  margin-bottom: 0;
  list-style-type: none;
}

#sidebar {
  border-right: 1px solid $sidebar-border-color;
  background: $sidebar-background-color;
}

.detail-screen {
  .product-title {
    font-size: 1rem;
    font-weight: 600;
    font-family: $body-font-family;
  }

  .mat-tab-body-wrapper {
    height: 100%;
  }
}

mat-list-item.no-wrap {
  height: initial !important;

  .mat-line {
    word-wrap: normal !important;
    white-space: normal !important;
  }
}

mat-list-item.pre-wrap {
  height: initial !important;

  .mat-line {
    word-wrap: normal !important;
    white-space: pre-wrap !important;
  }
}

.badge.badge-dotted {
  background: transparent;
  border: 1px dashed $wilco-blue;
  color: $wilco-blue;

  &:hover {
    color: $paperplate;
    background: $wilco-blue;
    cursor: pointer;
  }
}

// used in the delivery scheduler
.calendar-datepicker {
  width: 40px;
  .mat-form-field-wrapper {
    padding: 0;
  }
  .mat-form-field-underline {
    display: none;
  }
}

.dialog-no-padding .mat-dialog-container {
  padding: 0;
}

table,
mat-table {
  .mat-header-cell {
    font-family: 'Raleway', 'Helvetica Neue', sans-serif;
    font-weight: 600;
    color: #003b5c;
  }
}
