@import '_settings';

// Setup our text colors
@each $key, $value in $theme-colors {
  .text-#{$key} {
    color: $value;
  }
}

// Setup our background colors
@each $key, $value in $theme-colors {
  .bg-#{$key} {
    background-color: $value;
  }
}

// Setup badge outline colors
@each $key, $value in $theme-colors {
  .badge-outline-#{$key} {
    border: 1px solid $value;
    color: $value !important;
    background: transparent !important;
  }
}

@each $key, $value in $theme-colors {
  .badge-dashed-#{$key} {
    border: 2px dashed $value !important;
    color: contrast-color($value, $primary-color, $value) !important;
    background: transparent !important;
  }
}

@each $key, $value in $theme-colors {
  .badge-#{$key} {
    color: contrast-color($value, $primary-color, #ffffff) !important;
    background: $value !important;
  }
}

// Setup borders
@each $key, $value in $theme-colors {
  .dashed-border-#{$key} {
    border: 2px dashed $value !important;
    color: $value;
  }
}

.bg-light {
  background-color: #efefef !important;
}

.bg-violet {
  background-color: #4e3ec3 !important;
}

.bg-green {
  background-color: #9ce7ae !important;
  color: #112a46;
}

.bg-tan {
  background-color: #e3d5b5 !important;
  color: #112a46;
}

.bg-red {
  background-color: #ffcac7 !important;
  color: #112a46;
}

// TYPOGRAPHY
.text-21 {
  font-size: 1.3rem;
}

.text-18 {
  font-size: 1.2rem; // 16px
}

.text-16 {
  font-size: 1rem; // 16px
}

.text-14 {
  font-size: 14px;
}

.text-09 {
  font-size: 0.9rem;
}
.text-08 {
  font-size: 0.8rem;
}
.text-07 {
  font-size: 0.75rem; // 12px
}
.text-06 {
  font-size: 0.625rem; // 10px
}
.text-normal {
  font-weight: 400 !important;
}
.text-semibold {
  font-weight: 600 !important;
}
.text-bold {
  font-weight: bold;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.no-wrap {
  white-space: nowrap !important;
}

.hover-underline:hover {
  text-decoration: underline;
}
.uppercase {
  text-transform: uppercase;
}

// BACKGROUNDS
.bg-gray-darker {
  background: $wilco-gray-darker;
}

.bg-gray-dark {
  background-color: #d7d7d7;
}
.bg-gray {
  background: $wilco-gray-light;
}
.bg-gray-light {
  background: #efefef;
}
.bg-wilco-blue {
  background: $wilco-blue;
}
.bg-tan {
  background: #ddcba4;
}
.bg-white {
  background: #fff;
}

// FONT COLORS
.sub-text-color {
  color: #454243de;
}

.text-white {
  color: #ffffff;
}

.text-black {
  font-family: Montserrat;
  color: #1f1c1d;
}

.text-black-light {
  color: #454243;
}

.refund-badge {
  background-color: $wilco-info-danger !important;
}

.refund {
  color: $wilco-info-danger;
}

.text-info-danger {
  color: $wilco-info-danger;
}

// DISPLAY
.opacity-1 {
  opacity: 1 !important;
}
.opacity-05 {
  opacity: 0.5 !important;
}

// UTILITY
.cursor-pointer {
  cursor: pointer;
}

.bold-label {
  letter-spacing: 0.33px;
  font-weight: 600;
  color: #404040;

  span {
    font-weight: normal;
  }
}

.br-5 {
  border-radius: 5px;
}
// Borders
.border-bottom-dark {
  border-bottom: solid 1px $wilco-gray-darker;
}

.border-dark {
  border: solid 1px $wilco-gray-darker;
}

.br-10 {
  border-radius: 10px;
}

.br-30 {
  border-radius: 30px;
}

.circle {
  border-radius: 50%;
}

.mb-auto {
  margin-bottom: auto;
}
.br-5 {
  border-radius: 5px;
}

.border {
  border: 1px solid #dee2e6;
}

.border-top {
  border-top: 1px solid #dee2e6;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6;
}

.uppercase {
  text-transform: uppercase;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow-y: auto;
}

.list-style-none {
  list-style: none;
}

.underline {
  text-decoration: underline;
}

/**
* Create all possible height/width combinations
*/
@for $i from 1 through 20 {
  .h-#{$i * 5} {
    height: $i * 5% !important;
  }

  .w-#{$i * 5} {
    width: $i * 5% !important;
  }

  .vh-#{$i * 5} {
    height: $i * 5vh !important;
  }

  .vw-#{$i * 5} {
    width: $i * 5vw !important;
  }
}

$sizes: (
  '-0': 0,
  '-1': 0.3rem,
  '-2': 0.5rem,
  '-3': 1rem,
  '-4': 1.5rem,
  '-5': 2rem,
);

/**
 Setup all of our margins/padding
*/
@each $key, $value in $sizes {
  .m#{$key} {
    margin: $value;
  }

  .my#{$key} {
    margin-top: $value;
    margin-bottom: $value;
  }

  .mx#{$key} {
    margin-left: $value;
    margin-right: $value;
  }

  .mt#{$key} {
    margin-top: $value;
  }

  .mb#{$key} {
    margin-bottom: $value;
  }

  .ml#{$key} {
    margin-left: $value;
  }

  .mr#{$key} {
    margin-right: $value;
  }

  .p#{$key} {
    padding: $value;
  }

  .py#{$key} {
    padding-top: $value;
    padding-bottom: $value;
  }

  .px#{$key} {
    padding-left: $value;
    padding-right: $value;
  }

  .pt#{$key} {
    padding-top: $value;
  }

  .pb#{$key} {
    padding-bottom: $value;
  }

  .pl#{$key} {
    padding-left: $value;
  }

  .pr#{$key} {
    padding-right: $value;
  }
}
